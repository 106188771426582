export const assetBfkk = {
  id: 20,
  height: 409799,
  data: {
    icon: "/imgs/icons/asset/bfkk.png",
    about:
      "A group of hobbyists who live in Berlin and we like the feelings of the nature.",
    links: [
      {
        name: "Twitter: @sheenhuxin",
        url: "https://twitter.com/sheenhuxin",
        icon: "/imgs/icons/link-twitter.svg",
      },
    ],
  },
};
